import { debounce } from 'lodash';
import { onDomReady } from '../components/dynamic/observer';
import Jax from '../components/jax/jax';

class Search {
  constructor() {
    this.searchForm = document.querySelector('[data-search-form]');
    this.searchContainer = document.querySelector('[data-search-container]');

    // Отменяем отправку формы по Enter
    this.searchForm.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

    this.init();
  }

  init() {
    this.searchForm.addEventListener('input', debounce(() => {
      const jax = new Jax(this.searchForm.getAttribute('action'));
      jax.send(new FormData(this.searchForm)).then((html) => {
        this.searchContainer.innerHTML = html;

        const event = new Event('DOMContentMutated');
        document.dispatchEvent(event);
      });
    }, 200));
  }
}

onDomReady(() => {
  if (document.querySelector('[data-search-form]')) {
    new Search();
  }
});