// Как писать шаблоны смотрим тут: https://lodash.com/docs/4.17.15#template
export default `
  <div class="slot-message">
      <div class="slot-message__title">
          <%= title %>
      </div>

      <div class="slot-message__subtitle">
          <%= subtitle %>
      </div>

      <div class="slot-message__bottom">
          <div class="slot-message__additional">
              <%= additional %>
          </div>
          <div class="slot-message__date">
              <%= date %>
          </div>
      </div>
  </div>
`;