import _ from 'lodash';
import Notification from './Notification';
import notificationTemplate from './templates/_notification';

export default class Notifier {
  constructor(rootElement) {
    this.rootElement = rootElement;
    this.notificationTemplate = notificationTemplate;
    this.compiledTemplate = _.template(this.notificationTemplate);
  }

  notify(notificaitonOptions) {
    let options = {
      closable: true, // Показывать ли кнопку скрытия
      timeout: 2000, // ms - Сколько показывать уведомление
      template: null, // string|null - если передан шаблон, используется он
      templateName: 'default', // Имя шаблона, если не передан шаблон
      data: {}, // Данные для отображения шаблона
      href: null, // string|null - Переход по ссылке при клике на сообщение
    };
    Object.assign(options, notificaitonOptions);

    const item = new Notification(options);

    this.addNotification(item);
  }

  // Создаем объект нотификации
  // - Рендерим шаблон
  // - Биндим закрытие
  // - Биндим клик
  // - Ставим таймер
  addNotification(item) {
    const contentElement = item.getContentElement();

    const notificationElementHtmlString = this.compiledTemplate({
      closable: item.isClosable(),
    });

    const notificationItemElement = document.createElement('div');
    notificationItemElement.classList.add('notification');
    notificationItemElement.innerHTML = notificationElementHtmlString;
    notificationItemElement.querySelector('[data-container]').appendChild(contentElement);
    item.setNotificationElement(notificationItemElement);

    if (item.isClosable()) {
      notificationItemElement.querySelector('[data-close]').addEventListener('click', () => {
        this.stopCloseTimer(item);
        this.closeNotification(item);
      });
    }

    notificationItemElement.addEventListener('mouseover', () => {
      this.stopCloseTimer(item);
    });

    notificationItemElement.addEventListener('mouseout', () => {
      this.startCloseTimer(item);
    });

    if (item.getHref()) {
      contentElement.addEventListener('click', () => {
        window.location = item.getHref();
      });
    }

    console.log(this.rootElement);

    this.rootElement.appendChild(notificationItemElement);

    this.startCloseTimer(item);
  }

  stopCloseTimer(notification) {
    clearTimeout(notification.getAutoCloseTimer());
  }

  startCloseTimer(notification) {
    this.stopCloseTimer(notification);
    const timerId = setTimeout(() => {
      this.closeNotification(notification);
    }, notification.getTimeout());
    notification.setAutoCloseTimer(timerId);
  }

  closeNotification(notification) {
    const element = notification.getNotificationElement();
    element.classList.add('notification_out');
    setTimeout(() => {
      try {
        this.rootElement.removeChild(element);
      } catch (e) {
      }
    }, 3000);
  }
}