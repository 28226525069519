import IMask from 'imask';

const initPhoneField = () => {
  document.querySelectorAll('[data-phone-field]:not([data-initialized])').forEach((input) => {
    makeMask(input);
  });
};

function makeMask(input) {
  input.dataset.initialized = 'true';

  const dispatchMask = IMask(input, {
    mask: '+{7} (000) 000-00-00',
    prepare(str, masked) {
      // Check if it's the first character being typed and it's '8'
      if (masked.value.length === 0 && (str === '8' || str === '+8')) {
        return '7'; // Replace the first '8' with '7'
      }
      return str; // Keep other characters as they are
    },
  });
}

document.addEventListener('DOMContentLoaded', initPhoneField);
document.addEventListener('DOMContentMutated', initPhoneField);