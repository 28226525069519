import axios from 'axios';
import { onDomReady } from '../components/dynamic/observer';

class VkIdGetter {
  constructor() {
    this.initWidget();
    this.initObserver();
  }

  initWidget() {
    // eslint-disable-next-line no-undef
    VK.Widgets.AllowMessagesFromCommunity('vk_send_message', { height: 30 }, 227271760);
  }

  initObserver() {
    // eslint-disable-next-line no-undef
    VK.Observer.subscribe('widgets.allowMessagesFromCommunity.allowed', (userId) => {
      this.fetch(userId);
    });
  }

  fetch(userId) {
    axios.get(`/vkUser/${userId}`)
      .then((response) => {
        console.log(response);
      });
  }
}

onDomReady(() => {
  if (document.getElementById('vk_send_message')) {
    new VkIdGetter();
  }
});