import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class CustomSelect {
  static outsideReady = false;
  static entries = [];

  constructor(element) {
    element.dataset.initialized = 'true';
    CustomSelect.entries.push(this);
    this.isOpen = false;
    this.element = element;
    this.content = element.querySelector('[data-content]');
    this.opener = element.querySelector('[data-opener]');
    this.field = element.querySelector('[data-field]');
    this.replacedLabel = element.querySelector('[data-replaced-label]');
    this.openable = this.content && this.content.dataset.openable !== undefined && this.opener;
    this.inputs = Array.from(element.querySelectorAll('[data-input]'));
    this.filteredInputs = this.inputs;
    this.filterable = this.field && this.field.dataset.filterable !== undefined;
    this.chooseAll = this.field ? this.field.parentNode.parentNode.querySelector('[data-choose-all]') : null;

    this.bind();
  }

  bind() {
    this.bindOpenable();
    this.bindInputs();
    this.bindFilterable();
    this.bindChooseAll();
    if (!CustomSelect.outsideReady) {
      CustomSelect.bindOutsideClick();
    }
    this.listeners();
  }

  listeners() {
    const instance = this;
    this.element.addEventListener('disable', e => instance.disable());
    this.element.addEventListener('enable', e => instance.enable());
  }

  /**
   * Биндим клик по документу
   * Хватаем раскрытые селекты
   * Проверяем, если клик за пределами селекта, то закрываем селект
   */
  static bindOutsideClick() {
    document.addEventListener('click', (e) => {
      const targetElement = e.target;
      CustomSelect.entries.filter(entity => entity.isOpen).forEach(entity => {
        if (entity.element !== targetElement && !entity.element.contains(targetElement)) {
          entity.close();
        }
      });
    });
  }

  /**
   * Биндим ввод в поле фильтрации вариантов в селекте
   * Приводим данные из поля ввода и варианты к нижнему регистру для сравнения между собой
   */
  bindFilterable() {
    const instance = this;
    const filterInput = this.field ? this.field.parentNode.parentNode.querySelector('[data-filter-input]') : null;

    if (instance.filterable && filterInput) {
      if (filterInput.dataset.filterData) {
        instance.bindDataFilter(filterInput);
        return
      }

      filterInput.addEventListener('input', (e) => {
        const value = e.target.value.trim().toLowerCase();
        instance.filteredInputs = [];

        instance.inputs.forEach((input) => {
          const parent = input.parentNode;
          const text = parent.querySelector('[data-label-title]').innerHTML.trim().toLowerCase();
          if (text.includes(value)) {
            parent.classList.remove('_hidden');
            instance.filteredInputs.push(input);
          } else {
            parent.classList.add('_hidden');
          }
        });
        instance.setChooseAllByInputs(instance.filteredInputs);
      });
    }
  }

  bindDataFilter(filterInput) {
    filterInput.addEventListener('input', (e) => {
      let value = this.syncName(e.target.value);
      value = value.replace(/\s{2,}/gum, ' ');
      const words = value.trim().split(' ').filter((word) => word !== '');

      console.log(words, words.length)
      if (words.length) {
        this.filterProcess(words);
      } else {
        this.inputs.forEach((input) => {
          const parent = input.parentNode;
          parent.classList.remove('_hidden');
          parent.querySelectorAll('[data-search-value]').forEach((dataElem) => {
            console.log(dataElem)
            dataElem.parentNode.classList.remove('_show');
          })
        });
      }
    });
  }

  filterProcess(words) {
    const instance = this;
    this.inputs.forEach((input) => {
      const matchedWords = [];

      const parent = input.parentNode;
      parent.querySelectorAll('[data-search-value]').forEach((dataElem) => {
        const text = instance.syncName(dataElem.innerHTML);
        let hasMatch = false;


        words.forEach((word) => {
          if (text.includes(word)) {
            hasMatch = true;

            if (!matchedWords.includes(word)) {
              matchedWords.push(word);
            }
          }
        });

        if (hasMatch) {
          dataElem.parentNode.classList.add('_show');
        } else {
          dataElem.parentNode.classList.remove('_show');
        }
      });

      if (matchedWords.length !== words.length) {
        parent.classList.add('_hidden');
      } else {
        parent.classList.remove('_hidden');
      }
    });
  }

  syncName(value) {
    return  value.toLowerCase().replace(/[^a-zа-я0-9\s]/gum, '').trim();
  }

  /**
   * Биндим выбор всех вариантов во множественном селекте
   */
  bindChooseAll() {
    const instance = this;

    if (instance.chooseAll) {
      instance.chooseAll.addEventListener('change', () => {
        if (instance.filterable) {
         instance.handleInputsByAllChooseChange(instance.filteredInputs);
        } else {
          instance.handleInputsByAllChooseChange(instance.inputs);
        }
      })
    }
  }

  handleInputsByAllChooseChange(inputs) {
    const instance = this;
    if (instance.hasUncheckedInputs(inputs)) {
      inputs.forEach((input) => {
        input.checked = true;
        input.dispatchEvent(new Event('change'));
      });
    } else {
      inputs.forEach((input) => {
        input.checked = false;
        input.dispatchEvent(new Event('change'));
      });
    }
    instance.setChooseAllByInputs(inputs);
  }

  hasUncheckedInputs(inputs) {
    return inputs.some((input) => input.type === 'checkbox' && input.checked === false);
  }

  setChooseAllByInputs(inputs) {
    const instance = this;
    if (instance.chooseAll) {
      instance.chooseAll.checked = !instance.hasUncheckedInputs(inputs);
    }
  }

  /**
   * Биндим изменения инпутов
   * Закрываем выпадашку, если стоит data-auto-close="true"
   * Берем текст выбранного варианта и пихаем в лейбл или в поле см. setFieldValue()
   * Для множественного списка следим за пустым значением - либо оно либо что угодно другое, но не вместе
   */
  bindInputs() {
    const instance = this;
    this.inputs.forEach((input) => {
      input.addEventListener('change', () => {
        if (input.type === 'checkbox') {
          instance.checkBoxHandler(input);
          instance.setChooseAllByInputs(instance.inputs);
        }
        if (instance.openable && input.dataset.autoClose !== undefined) {
          instance.close();
        }

        if (instance.field || instance.replacedLabel) {
          instance.setFieldValue();
        }
      });
    });
  }

  checkBoxHandler(changeInput) {
    const notValueInput = this.inputs.find(input => input.value === '0')
    if (!notValueInput) {
      return;
    }

    if (changeInput === notValueInput) {
      if (changeInput.checked) {
        this.inputs.filter(input => input !== notValueInput).forEach(input => input.checked = false);
      }

      return;
    }

    const checkedInputs = this.inputs.filter(input => input.checked === true && input.value !== '0');
    if (checkedInputs.length) {
      notValueInput.checked = false;
    } else {
      notValueInput.checked = true;
    }
  }


  /**
   * Формируем строку из выбранного значения или значений и вставляем в лейбак или в поле
   * Если селект фильтрующийся - возвращаем отображение всех вариантов
   */
  setFieldValue() {
    let stringValue = '';
    this.inputs.filter(input => input.checked).forEach((input) => {
      const label = input.parentNode.querySelector('[data-label-title]');
      if (!label) return;
      const string = label.innerHTML.trim();
      stringValue += stringValue ? `, ${string}` : string;
    });

    if (this.field) {
      this.field.value = stringValue;
    }

    if (this.replacedLabel) {
      this.replacedLabel.innerHTML = stringValue;
    }

    if (this.filterable) {
      // this.inputs.forEach(input => input.parentNode.classList.remove('_hidden'));
    }
  }

  /**
   * Биндим открытие/закрытие
   */
  bindOpenable() {
    if (this.openable) {
      const instance = this;
      this.opener.addEventListener('click', (e) => {
        e.preventDefault();
        instance.isOpen ? instance.close() : instance.open();
      });
    }
  }

  close() {
    this.isOpen = false;
    this.element.classList.remove('_open');
    this.element.dispatchEvent(new CustomEvent('CustomSelectClose'));
  }

  open() {
    this.isOpen = true;
    this.element.classList.add('_open');
    this.element.dispatchEvent(new CustomEvent('CustomSelectOpen'));
  }

  enable() {
    this.element.classList.remove('_disable');
  }

  disable() {
    this.close();
    this.element.classList.add('_disable');
  }
}
function initCustomSelects() {
  const fields = document.querySelectorAll('[data-custom-select]:not([data-initialized="true"])');
  fields.forEach(field => new CustomSelect(field));
}

onDomChanges(() => {
  setTimeout(initCustomSelects, 300);
});