import { onDomReady } from '../../components/dynamic/observer';
import Panel from './parts/panel';
import Filter from './parts/filter';
import SlotTable from './parts/table';

class SlotsPage {
  static panelInstance = null;
  static filterInstance = null;
  static tableInstance = null;

  constructor(page) {
    SlotsPage.categoryId = page.dataset.categoryId;

    const panelElement = document.querySelector('[data-slots-panel]');
    SlotsPage.panelInstance = panelElement ? new Panel(panelElement) : null;

    const filterForm = document.querySelector('[data-slots-filter]');
    SlotsPage.filterInstance = filterForm ? new Filter(filterForm) : null;

    const tableContainer = document.querySelector('[data-slots-data-wrap]');
    SlotsPage.tableInstance = tableContainer ? new SlotTable(tableContainer) : null;
  }
}

export default SlotsPage;

onDomReady(() => {
  const page = document.querySelector('[data-slots-page]');
  if (page) {
    new SlotsPage(page);
  }
});