import Notifier from './Notifier';

function init() {
  if (!window.notifier) {
    window.notifier = new Notifier(document.querySelector('[data-notifier]'));
    document.querySelectorAll('[data-flash-notification]')
      .forEach((el) => {
        notify({
          templateName: el.dataset.templateName,
          data: {
            message: el.dataset.message,
          },
        });
      });
  }
  /*
  notify({
    templateName: 'button',
    timeout: 30000,
    data: {
      message: 'Тут сообщение! 2 ad d d d d d d d d d d d d d d d d d d',
      buttonName: 'Перейти в личный кабинет',
      buttonUrl: '/customer',
    },
  });

  notify({
    templateName: 'success',
    timeout: 30000,
    data: {
      message: 'Тут сообщение!',
    },
  });
   */
}

/**
 * Обычное уведомление
 *
 * @param notificationOptions
 */
function notify(notificationOptions) {
  window.notifier.notify(notificationOptions);
}

/**
 * Уведомление из ответа сервера
 *
 * @return bool - было ли показано уведомление или нет
 */
function notifyResponse(response, additionalOptions) {
  if (response && response.data) {
    // Считаем, что весь ответ - это уведомление
    let notification = response.data;
    // Проверяем, если уведомление лежит по ключу notification
    if (typeof notification === 'object' && typeof notification.notification === 'object') {
      notification = notification.notification;
    }
    if (notification.notification !== 'notification') {
      return false;
    }

    let notificationOptions = {};
    Object.assign(notificationOptions, additionalOptions);
    Object.assign(notificationOptions, notification);
    notify(notificationOptions);
    return true;
  }
  return false;
}

init();

export {
  notify,
  notifyResponse,
};