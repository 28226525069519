import _ from 'lodash';
import templates from './templates';

export default class Notification {
  constructor(options) {
    this.closable = options.closable;
    this.timeout = options.timeout ? options.timeout : 5000;
    this.template = options.template;
    this.templateName = options.templateName;
    this.data = options.data;
    this.href = options.href;
    this.contentElement = undefined;
    this.autoCloseTimer = undefined;
    this.notificationElement = undefined;
  }

  getContentElement() {
    if (this.contentElement) {
      return this.contentElement;
    }

    let templateText = '';
    if (this.template) {
      templateText = this.template;
    } else if (this.templateName) {
      if (templates[this.templateName]) {
        templateText = templates[this.templateName];
      }
    }
    const compiled = _.template(templateText);
    const htmlString = compiled(this.data);

    const element = document.createElement('div');
    element.classList.add('notification__content');
    element.innerHTML = htmlString.trim();
    this.contentElement = element;
    return this.contentElement;
  }

  isClosable() {
    return this.closable;
  }

  getTimeout() {
    return this.timeout;
  }

  getTemplate() {
    return this.template;
  }

  getTemplateName() {
    return this.templateName;
  }

  getData() {
    return this.data;
  }

  getHref() {
    return this.href;
  }

  setAutoCloseTimer(timerId) {
    this.autoCloseTimer = timerId;
  }

  getAutoCloseTimer() {
    return this.autoCloseTimer;
  }

  setNotificationElement(notificationElement) {
    this.notificationElement = notificationElement;
  }

  getNotificationElement() {
    return this.notificationElement;
  }
}
