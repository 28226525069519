import defaultTemplate from './templates/default';
import errorTemplate from './templates/error';
import buttonTemplate from './templates/button';
import successTemplate from './templates/success';
import slotSuccessTemplate from './templates/slot_success';

export default {
  default: defaultTemplate,
  error: errorTemplate,
  success: successTemplate,
  slot_success: slotSuccessTemplate,
  button: buttonTemplate,
};