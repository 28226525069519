import axios from 'axios';
import { validatorValidateForm, validatorCleanErrors } from '../../../components/forms/validation';
import SlotsPage from '../index';
import { notify } from '../../notifications/notify';
import { logPlugin } from '@babel/preset-env/lib/debug';

class SaveForm {
  constructor(modal) {
    this.modal = modal;
    this.container = modal.content.querySelector('[data-slot-save-form-container]');
    this.form = this.container.querySelector('[data-slot-save-form]');
    this.formName = this.form.dataset.formName;
    this.actionType = this.form.dataset.slotSaveForm;

    this.addLink = this.container.querySelector('[data-additional-link]');
    this.addForm = this.container.querySelector('[data-multi-additional-form]');

    this.isNew = this.actionType === 'create';

    if (this.isNew) {
      this.targetDay = this.form.dataset.day;
      this.groupId = this.form.dataset.groupId;
    } else {
      this.slotId = this.form.dataset.slotId;
    }

    this.bind();
  }

  bind() {
    this.bindSubmit();

    if (!this.isNew) {
      this.bindRemove();
    }

    if (this.addLink) {
      this.bindAddLink();
    }

    if (this.addForm) {
      this.bindAddForm();
    }

    if (this.addForm) {
      this.bindAdditionalRemove();
    }
  }

  bindAddForm() {
    const instance = this;

    this.addForm.addEventListener('submit', (e) => {
      e.preventDefault();
      const formData = instance.getFormData(false);
      instance.cleanErrors();

      const addForm = instance.getMultiAddData();

      for (const [key, value] of Object.entries(addForm)) {
        formData.append(key, value);
      }

      axios.post('/slots/get_additional_form_list', formData)
        .then((response) => {
          const { data } = response;
          if (data.errors) {
            instance.handleErrors(data.errors);
          } else {
            const currentList = instance.container.querySelector('[data-additional-list]');
            data.forEach((item) => {
              const newData = document.createElement('div');
              newData.innerHTML = item.html;
              currentList.append(newData.querySelector('form'));
            });

            const accordionElement = instance.addForm.querySelector('[data-accordion-element]');
            if (accordionElement) {
              if (accordionElement.classList.contains('_opened')) {
                const accordionLink = accordionElement.querySelector('[data-accordion-link]');
                if (accordionLink) {
                  accordionLink.dispatchEvent(new Event('click'));
                }
              }
            }

            document.dispatchEvent(new Event('DOMContentMutated'));
            instance.bindAdditionalRemove();
          }
        })
        .catch((response) => { console.log(response); });
    });
  }

  getMultiAddGroups() {
    return Array.from(this.addForm.querySelectorAll('input[type="checkbox"]:checked'))
      .map((input) => input.value);
  }

  getMultiAddTime() {
    return this.addForm.querySelector('input[type="radio"]:checked').value;
  }

  getMultiAddDay() {
    return this.addForm.querySelector('input[type="date"]').value;
  }

  getMultiAddData() {
    const multiAddData = {};
    multiAddData.group_ids = this.getMultiAddGroups();

    const coverTime = this.getMultiAddTime();
    if (coverTime) {
      multiAddData.time = coverTime;
    }

    const coverDay = this.getMultiAddDay();
    if (coverDay) {
      multiAddData.day = coverDay;
    }

    return multiAddData;
  }

  bindAddLink() {
    const instance = this;

    this.addLink.addEventListener('click', (e) => {
      e.preventDefault();
      const formData = instance.getFormData(false);
      instance.cleanErrors();

      axios.post('/slots/get_additional_form', formData)
        .then((response) => {
          const { data } = response;
          if (!data.success) {
            instance.handleErrors(data.errors);
          } else {
            const currentList = instance.container.querySelector('[data-additional-list]');
            const newData = document.createElement('div');
            newData.innerHTML = data.html;

            currentList.append(newData.querySelector('form'));
            document.dispatchEvent(new Event('DOMContentMutated'));
            instance.bindAdditionalRemove();
          }
        })
        .catch((response) => { console.log(response); });
    });
  }

  bindAdditionalRemove() {
    this.container.querySelectorAll('[data-additional-delete]:not([data-initialized="true"])').forEach((link) => {
      link.dataset.initialized = true;
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const element = link.closest('[data-additional-item]');
        element.remove();
      });
    });
  }

  getFormData(withMoreRecords) {
    const formData = new FormData(this.form);
    formData.set('additional', this.getAdditionalData(withMoreRecords));

    return formData;
  }

  bindRemove() {
    const instance = this;
    const removeButton = this.container.querySelector('[data-action-delete]');
    const removeAllButton = this.container.querySelector('[data-action-delete-all]');

    if (removeButton) {
      removeButton.addEventListener('click', (e) => {
        e.preventDefault();
        instance.request('/slots/delete', { slot_id: instance.slotId });
      });
    }

    if (removeAllButton) {
      removeAllButton.addEventListener('click', (e) => {
        e.preventDefault();
        instance.request('/slots/delete-all', { slot_id: instance.slotId });
      });
    }
  }

  bindSubmit() {
    const instance = this;
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      instance.cleanErrors();

      const formData = instance.getFormData(true);
      instance.request(instance.form.getAttribute('action'), formData);
    });
  }

  request(url, formData) {
    const instance = this;
    axios.post(url, formData)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          instance.handleSuccess(data);
        } else {
          instance.handleErrors(data.errors);
        }
      })
      .catch((response) => {
        console.log(response);
      });
  }

  getAdditionalData(withMoreRecords) {
    const additionalData = this.isNew ? {
      group_id: this.groupId,
      day: this.targetDay,
    } : {
      slot_id: this.slotId,
    };

    if (withMoreRecords) {
      additionalData.moreRecords = this.extractMoreRecords();
    }

    return JSON.stringify(additionalData);
  }

  extractMoreRecords() {
    const moreRecords = [];
    const addForms = this.container.querySelectorAll('[data-additional-item]');
    Array.from(addForms).forEach((addForm) => {
      const { slotId } = addForm.dataset;
      const formData = new FormData(addForm);
      const moreData = {};
      formData.append('idPrefix', addForm.dataset.additionalItem);
      if (slotId) {
        formData.append('slotId', slotId);
      }
      formData.forEach((value, name) => {
        moreData[name] = value;
      });
      moreRecords.push(moreData);
    });

    return moreRecords;
  }

  cleanErrors() {
    validatorCleanErrors(this.form, this.formName);
    this.container.querySelectorAll('[data-additional-item] [data-errors]').forEach((list) => {
      list.innerHTML = null;
    });
  }

  handleSuccess(data) {
    const slotsInfo = data.slots_info;
    if (slotsInfo && slotsInfo.length) {
      slotsInfo.forEach((info) => {
        console.log(info);
        SlotsPage.tableInstance.findAndReplaceCell(info);
      });
    }

    // TODO хз куда тебе тут это впихнуть - как то развести бы удаление создание и апдейт
    // Посмотрел в слот инфо - там кусок слота, может быть имеет толк хранить от слота что нибудь
    // для вывода в нотификейшен - вобщем если дашь добро - пни меня, я потыкаюсь
    notify({
      templateName: 'slot_success',
      timeout: 8000,
      data: data.notify,
    });

    this.modal.close();
  }

  handleErrors(errors) {
    const instance = this;
    const { additional } = errors;
    if (additional) {
      Object.entries(additional).forEach((item) => {
        instance.setAddErrors(item[0], item[1]);
      });

      delete errors.additional;
    }
    validatorValidateForm(this.form, this.formName, errors);
  }

  setAddErrors(prefix, errors) {
    const list = this.container.querySelector(`[data-additional-item="${prefix}"] [data-errors]`);
    if (list) {
      list.innerHTML = '';
      errors.forEach((error) => {
        const element = document.createElement('li');
        element.innerHTML = error;
        list.append(element);
      });
    }
  }
}

export default SaveForm;