import { onDomChanges, onDomReady } from "../../components/dynamic/observer";

class PasswordInput {
    constructor(element) {
        element.dataset.initialized = 'true';
        this.element = element;
        this.input = this.element.getElementsByTagName('input')[0];
        this.toggleButton = this.element.querySelector('[data-password-input-link]');

        this.bind();
    }

    bind() {
        this.bindToggleButton();
        this.bindFilledInput();
    }

    bindToggleButton() {
        const instance = this;
        instance.toggleButton.addEventListener('click', () => {
            const type = instance.input.getAttribute('type') === 'password' ? 'text' : 'password';
            instance.input.setAttribute('type', type);
            instance.element.classList.toggle('_visible');
        });
    }

    bindFilledInput() {
        const instance = this;
        instance.input.addEventListener('input', (e) => {
            instance.toggleClassFilled(e.target.value);
        });
    }

    toggleClassFilled(value) {
        const instance = this;
        if (value) {
            instance.element.classList.add('_filled');
        } else {
            instance.element.classList.remove('_filled');
        }
    }
}
function initPasswordInputs() {
    const fields = document.querySelectorAll('[data-password-input]:not([data-initialized="true"])');
    fields.forEach((field) => new PasswordInput(field));
}

onDomChanges(() => {
    setTimeout(initPasswordInputs, 300);
});