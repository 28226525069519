import { onDomChanges } from '../components/dynamic/observer';
import Jax from '../components/jax/jax';

class Pay {
  constructor(form) {
    this.form = form;
    this.payButton = form.querySelector('[data-pay]');
    this.closeButton = null;
    this.copyButton = null;
    this.copyWithText = null;
    this.init();
  }

  init() {
    if (this.form) {
      this.payButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.submitForm();
      });
    }
  }

  submitForm() {
    const jax = new Jax(this.form.getAttribute('action'), this.form.getAttribute('method'));
    const formData = new FormData(this.form);

    jax.send(formData)
      .then((data) => this.replaceContent(data));
  }

  replaceContent(content) {
    if (this.form) {
      this.form.innerHTML = content;
      this.closeButton = this.form.querySelector('[data-close]');
      this.copyButton = this.form.querySelector('[data-copy]');
      this.copyWithText = this.form.querySelector('[data-copy-with-text]');
      if (this.closeButton && this.copyButton) {
        this.bindReplacedButtons();
      }
    }
  }

  bindReplacedButtons() {
    const result = this.form.querySelector('[data-result]');

    this.closeButton.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('.modal__closer').click();
    });

    this.copyButton.addEventListener('click', async () => {
      const { value } = this.form.querySelector('input');
      await navigator.clipboard.writeText(value);
      result.classList.add('_copy');
    });

    this.copyWithText.addEventListener('click', async (e) => {
      const { sum } = e.target.dataset;
      const { value } = this.form.querySelector('input');
      const text = `С нашим договором оферты вы можете ознакомиться по ссылке - https://vk.com/@smm_pro43-publichnaya-oferta2

К оплате будет ${sum} рублей

Оплатить можно по ссылке:
${value}

Ссылка ведёт на окно онлайн оплаты "Юкасса". После того как вы перешли по ссылке, у вас будет 10 минут на оплату. Если вы не успели, то напишите нам, мы создадим новую ссылку на оплату.`;

      await navigator.clipboard.writeText(text);
      result.classList.add('_copy');
    });
  }
}

onDomChanges(() => {
  const form = document.querySelector('[data-pay-form]');
  if (form) {
    // eslint-disable-next-line no-new
    new Pay(form);
  }
});