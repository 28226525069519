import axios from 'axios';

class BoardCats {
  constructor(element) {
    this.container = element;

    this.fetch();
  }

  fetch() {
    const instance = this;
    axios.get('/dashboard/board-cats')
      .then((response) => {
        instance.updateData(response.data);
      });
  }

  updateData(data) {
    data.forEach((cat) => {
      const catId = cat.category;
      cat.info.forEach((info) => {
        const selector = [
          `[data-category-id="${catId}"]`,
          `[data-info-item="${info.name}"]`,
          '[data-info-value]',
        ];

        const element = this.container.querySelector(selector.join(' '));
        if (element) {
          element.classList.remove('_loading');
          element.innerHTML = info.value;
        }
      });
    });
  }
}

export default BoardCats;