import Jax from '../../components/jax/jax';
import LiveEvent from '../../components/live/live';
import { notify } from '../notifications/notify';
import axios from 'axios';

class FilterForm {
  constructor(form) {
    this.form = form;
    this.key = form.dataset.defaultFilterForm;
    this.contentSelector = `[data-default-filter-content="${this.key}"]`;
    this.contentWrap = document.querySelector(this.contentSelector);
    this.hasPreloaderElement = this.contentWrap.closest('[data-has-filter-preloader]');
    this.debounceOrder = 0;
    this.bind();

    this.request();
  }

  bind() {
    this.bindInputs();
    this.bindActions();
    this.bindModals();
    this.bindListeners();
  }

  bindInputs() {
    const instance = this;
    const inputs = this.form.querySelectorAll('input[type="datetime-local"]');
    inputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        instance.debounceRequest();
      });
    });
  }

  bindListeners() {
    this.form.querySelectorAll('[data-custom-select]').forEach((element) => {
      element.addEventListener('CustomSelectOpen', (e) => {
        const accordion = element.closest('[data-accordion-block]');
        if (accordion) {
          accordion.classList.add('_visible');
        }
      });
      element.addEventListener('CustomSelectClose', (e) => {
        const accordion = element.closest('[data-accordion-block]');
        if (accordion) {
          accordion.classList.remove('_visible');
        }
      });
    });
  }

  bindActions() {
    const instance = this;
    new LiveEvent('click', '[data-filterable-item] [data-action]:not([data-modal])', function activateAction(e) {
      e.preventDefault();
      const link = this;
      const wrap = link.closest('[data-default-filter-content]');
      if (wrap.dataset.defaultFilterContent === instance.key) {
        const action = link.getAttribute('href');
        axios.get(action).then((response) => {
          if (response.data.success) {
            instance.debounceRequest();
            notify({
              timeout: 2000,
              templateName: 'success',
              data: {
                message: response.data.message,
              },
            });

            const events = link.dataset.events;
            if (events) {
              const eventsArray = events.split(',').map((item) => item.trim());
              eventsArray.forEach((event) => {
                document.dispatchEvent(new Event(event));
              });
            }
          }
        });
      }
      return false;
    });
  }

  bindModals() {
    const instsance = this;
    document.addEventListener('formSubmit', (e) => {
      const link = e.element;
      if (link) {
        const wrap = link.closest('[data-default-filter-block]');
        if (wrap) {
          instsance.debounceRequest();
        }
      }
    });
  }

  debounceRequest() {
    const instance = this;
    this.debounceOrder++;

    setTimeout(() => {
      this.debounceOrder--;
      if (this.debounceOrder <= 0) {
        instance.request();
      }
    }, 400);
  }

  request() {
    const instance = this;
    const { form } = instance;
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const jax = new Jax(action, method, false);

    if (this.hasPreloaderElement) {
      this.hasPreloaderElement.classList.add('_loading');
    }

    jax.send(new FormData(form)).then((html) => {
      instance.replaceContent(html);
    });
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newContent = page.querySelector(this.contentSelector);
    this.contentWrap.innerHTML = newContent.innerHTML;

    if (this.hasPreloaderElement) {
      this.hasPreloaderElement.classList.remove('_loading');
    }
  }
}

export default FilterForm;