// Как писать шаблоны смотрим тут: https://lodash.com/docs/4.17.15#template
export default `
  <div class="notification-message notification-message_button">
    <div class="notification-message__text">
        <%= message %>
    </div>
    
    <div class="button button_filter-wrapper button_primary notification-message__button">
        <a href="<%= buttonUrl %>" class="button__link">
            <%= buttonName %>
        </a>
        <span class="button__filter"></span>
    </div>
  </div>
`;