import { onDomReady } from '../../../components/dynamic/observer';
import Jax from '../../../components/jax/jax';

class GroupMatcher {
  constructor(block) {
    this.block = block;
    this.dbId = null;
    this.vkId = null;

    this.init();
  }

  async init() {
    await this.fetch();
  }

  bind() {
    const instance = this;
    this.block.querySelectorAll('input[type=radio]')
      .forEach((element) => element.addEventListener('change', (e) => {
        const inputName = element.name;

        if (inputName === 'db-item') {
          instance.dbId = element.value;
        } else {
          instance.vkId = element.value;
        }

        if (instance.dbId && instance.vkId) {
          instance.submit();
        }
      }));
  }

  async fetch() {
    const instance = this;
    const form = this.block.querySelector('form');
    const jax = new Jax(form.getAttribute('action'), 'GET');
    await jax.send(new FormData(form))
      .then((data) => instance.handleResponse(data));
  }

  async submit() {
    const instance = this;
    const form = this.block.querySelector('form');
    const jax = new Jax(form.getAttribute('action'), 'POST');
    await jax.send(new FormData(form))
      .then((data) => instance.handleResponse(data));
  }

  handleResponse(data) {
    const newContent = document.createElement('div');
    newContent.innerHTML = data;

    const oldForm = this.block.querySelector('form');
    oldForm.innerHTML = newContent.querySelector('form').innerHTML;
    this.vkId = null;
    this.dbId = null;
    this.bind();
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-group-matcher]');
  if (element) {
    new GroupMatcher(element);
  }
});