import LiveEvent from '../../components/live/live';
import Modal from '../../components/modal/modal';
import { notify } from '../notifications/notify';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: 'x',
    closeOnSuccess: true,
    closeOnClickBg: false,
    onFormSuccess() {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);

      const { events } = link.dataset;
      console.log(link);
      if (events) {
        const eventsArray = events.split(',').map((item) => item.trim());
        eventsArray.forEach((event) => {
          document.dispatchEvent(new Event(event));
        });
      }
    },
  });
  return false;
});

new LiveEvent('click', '[data-client-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: 'x',
    closeOnClickBg: false,
    onFormSuccess() {
      location.reload();
    },
  });
  return false;
});

new LiveEvent('click', '[data-invoice-change-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: 'x',
    closeOnSuccess: true,
    closeOnClickBg: false,
    closeOnSuccessDelay: 0,
    onFormSuccess() {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);

      const { events } = link.dataset;
      if (events) {
        const eventsArray = events.split(',').map((item) => item.trim());
        eventsArray.forEach((event) => {
          document.dispatchEvent(new Event(event));
        });
      }

      notify({
        templateName: 'success',
        timeout: 2000,
        data: {
          message: 'Счет изменен',
        },
      });
    },
  });
  return false;
});

new LiveEvent('click', '[data-invoice-create-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: 'x',
    closeOnClickBg: false,
    closeOnSuccess: true,
    closeOnSuccessDelay: 0,
    onFormSuccess() {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);

      const { events } = link.dataset;
      if (events) {
        const eventsArray = events.split(',').map((item) => item.trim());
        eventsArray.forEach((event) => {
          document.dispatchEvent(new Event(event));
        });
      }

      notify({
        templateName: 'success',
        timeout: 2000,
        data: {
          message: 'Счет создан',
        },
      });
    },
  });
  return false;
});