import axios from 'axios';

class EventReplacer {
  constructor(block) {
    this.block = block;
    this.event = block.dataset.eventReplacer;
    this.url = block.dataset.eventReplacerUrl;
    if (this.event && this.url) {
      this.init();
    }
  }

  init() {
    this.bind();
  }

  bind() {
    const instance = this;
    document.addEventListener(this.event, (e) => {
      axios.get(instance.url)
        .then((response) => {
          instance.replaceContent(response.data);
        });
    });
  }

  replaceContent(html) {
    const page = document.createElement('div');
    page.innerHTML = html;
    const newContent = page.querySelector(`[data-event-replacer="${this.event}"]`);
    this.block.innerHTML = newContent.innerHTML;

    // Еще пригодится
    // if (this.hasPreloaderElement) {
    //   this.hasPreloaderElement.classList.remove('_loading');
    // }
  }
}

export default EventReplacer;