import { onDomReady } from '../../components/dynamic/observer';
import axios from "axios";

class Sidebar {
  constructor(element) {
    this.element = element;
    this.layout = document.querySelector('[data-sidebared-layout]');
    this.closer = element.querySelector('[data-sidebar-closer]');
    this.opener = document.querySelector('[data-sidebar-opener]');

    this.bind();
  }

  bind() {
    const instance = this;
    if (this.closer && instance.layout) {
      this.closer.addEventListener('click', (e) => {
        e.preventDefault();
        instance.layout.classList.add('_hide-menu');
        axios.post('/user/settings', { show_sidebar: false });
      });
    }

    if (this.opener && instance.layout) {
      this.opener.addEventListener('click', (e) => {
        e.preventDefault();
        instance.layout.classList.remove('_hide-menu');
        axios.post('/user/settings', { show_sidebar: true });
      });
    }
  }
}

onDomReady(() => {
  const element = document.querySelector('[data-sidebar]');
  if (element) {
    new Sidebar(element);
  }
});