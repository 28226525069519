import axios from 'axios';

class Panel {
  constructor(panelElement) {
    this.element = panelElement;
    this.switchCategory = panelElement.querySelector('[data-switch-category]');
    this.switchFilter = panelElement.querySelector('[data-switch-filter]');

    this.categoryOpener = document.querySelector('[data-slots-categories-opener]');
    this.filterOpener = document.querySelector('[data-slots-filter-opener]');

    this.layout = document.querySelector('[data-sidebared-layout]');

    this.bind();
  }

  bind() {
    this.bindButtons();
  }

  bindButtons() {
    const instance = this;
    if (this.switchCategory && this.categoryOpener) {
      this.switchCategory.addEventListener('change', (e) => {
        e.target.checked ? instance.openCategories() : instance.closeCategories();
      });
    }

    if (this.switchFilter && this.filterOpener) {
      this.switchFilter.addEventListener('change', (e) => {
        e.target.checked ? instance.openFilter() : this.closeFilter();
      });
    }
  }

  openCategories() {
    this.categoryOpener.dispatchEvent(new Event('click'));
    this.layout.classList.add('_opened-categories');
    axios.post('/user/settings', { show_categories: true });
  }

  closeCategories() {
    this.categoryOpener.dispatchEvent(new Event('click'));
    this.layout.classList.remove('_opened-categories');
    axios.post('/user/settings', { show_categories: false });
  }

  openFilter() {
    this.filterOpener.dispatchEvent(new Event('click'));
    this.layout.classList.add('_opened-filters');
    axios.post('/user/settings', { show_filter: true });
  }

  closeFilter() {
    this.filterOpener.dispatchEvent(new Event('click'));
    this.layout.classList.remove('_opened-filters');
    axios.post('/user/settings', { show_filter: false });
  }
}

export default Panel;