import moment from 'moment';
import Jax from '../../../components/jax/jax';
import SlotTable from './table';
import SlotsPage from '../index';

class Filter {
  constructor(filterFormElement) {
    this.form = filterFormElement;
    this.contentElement = document.querySelector('[data-slots-data-wrap]');
    this.preloader = document.querySelector('[data-content-preloader]');

    this.debounceOrder = 0;
    this.debounceGroup = 0;

    this.dateInputs = this.form.querySelectorAll('input[type="date"]');
    this.groupInputs = this.form.querySelectorAll('[data-group-filter] [data-input]');

    this.dateToIncButton = filterFormElement.querySelector('[data-to-inc]');
    this.dateToDecButton = filterFormElement.querySelector('[data-to-dec]');
    this.dateFromIncButton = filterFormElement.querySelector('[data-from-inc]');
    this.dateFromDecButton = filterFormElement.querySelector('[data-from-dec]');

    this.monthButtons = document.querySelectorAll('[data-month-button]');
    this.bind();
    this.request();
  }

  bind() {
    this.bindInputs();
    this.listenInputs();
    this.bindIncButtons();
    this.bindMonthButtons();
  }

  bindIncButtons() {
    this.dateToIncButton.addEventListener('click', () => {
      this.changeInput('SlotFilterForm_to', 1);
    });
    this.dateToDecButton.addEventListener('click', () => {
      this.changeInput('SlotFilterForm_to', -1);
    });
    this.dateFromIncButton.addEventListener('click', () => {
      this.changeInput('SlotFilterForm_from', 1);
    });
    this.dateFromDecButton.addEventListener('click', () => {
      this.changeInput('SlotFilterForm_from', -1);
    });
  }

  bindMonthButtons() {
    this.monthButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        this.setMonthsInactive();
        e.target.classList.add('_active');
        const { from } = e.target.dataset;
        const { to } = e.target.dataset;
        this.setDateToInput('SlotFilterForm_from', from);
        this.setDateToInput('SlotFilterForm_to', to);
        this.debounceRequest();
      });
    });
  }

  changeInput(type, amount) {
    const dateInput = document.getElementById(type);
    const dateValue = moment(dateInput.value).add(amount, 'month');
    dateInput.value = dateValue.format('yyyy-MM-DD');
    this.setMonthsInactive();
    this.debounceRequest();
  }

  setMonthsInactive() {
    this.monthButtons.forEach((button) => {
      button.classList.remove('_active');
    });
  }

  setDateToInput(type, value) {
    const dateInput = document.getElementById(type);
    dateInput.value = value;
  }

  listenInputs() {
    this.form.querySelectorAll('[data-custom-select]').forEach((element) => {
      element.addEventListener('CustomSelectOpen', (e) => {
        const accordion = element.closest('[data-accordion-block]');
        if (accordion) {
          accordion.classList.add('_visible');
        }
      });
      element.addEventListener('CustomSelectClose', (e) => {
        const accordion = element.closest('[data-accordion-block]');
        if (accordion) {
          accordion.classList.remove('_visible');
        }
      });
    });
  }

  bindInputs() {
    const instance = this;
    this.dateInputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        instance.debounceRequest();
      });
    });

    this.groupInputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        instance.debounceGroupFilter();
      });
    });
  }

  debounceGroupFilter() {
    this.debounceGroup++;
    setTimeout(() => {
      this.debounceGroup--;
      if (this.debounceGroup <= 0) {
        this.debounceGroup = 0;
        this.groupFilter();
      }
    }, 100);
  }

  groupFilter() {
    const shows = [];
    const hides = [];

    this.groupInputs.forEach((input) => {
      input.checked ? shows.push(input.value) : hides.push(input.value);
    });

    if (shows.length === 0 || hides.length === 0) {
      SlotsPage.tableInstance.showAllGroups();
    } else {
      shows.forEach((groupId) => { SlotsPage.tableInstance.showGroup(groupId); });
      hides.forEach((groupId) => { SlotsPage.tableInstance.hideGroup(groupId); });
    }
  }

  debounceRequest() {
    this.debounceOrder++;
    setTimeout(() => {
      this.debounceOrder--;
      if (this.debounceOrder <= 0) {
        this.debounceOrder = 0;
        this.request();
      }
    }, 1500);
  }

  async request() {
    this.showPreloader();
    const instance = this;
    const jax = new Jax(this.form.getAttribute('action'), this.form.getAttribute('method'));

    const formData = new FormData(this.form);

    jax.send(new FormData(this.form))
      .then((data) => instance.handleResponse(data))
      .catch((data) => instance.handleError(data));
  }

  handleResponse(data) {
    const page = document.createElement('div');
    page.innerHTML = data;
    this.replace(page);
    this.hidePreloader();
  }

  handleError(data) {
    console.log(data);
    this.hidePreloader();
  }

  replace(page) {
    const newData = page.querySelector('[data-slots-data]');
    this.contentElement.innerHTML = newData.outerHTML;
  }

  showPreloader() {
    if (this.preloader) {
      this.preloader.classList.add('_active');
    }
  }

  hidePreloader() {
    if (this.preloader) {
      this.preloader.classList.remove('_active');
    }
  }
}

export default Filter;