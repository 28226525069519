import { onDomReady } from '../../components/dynamic/observer';
import BoardCats from './board-cats';

class Dashboard {
  static boardCatsInstance;

  constructor(page) {
    this.page = page;

    const cats = document.querySelector('[data-board-cats]');
    if (cats) {
      Dashboard.boardCatsInstance = new BoardCats(cats);
    }
  }
}
onDomReady(() => {
  const page = document.querySelector('[data-dashboard-page]');
  if (page) {
    new Dashboard(page);
  }
});